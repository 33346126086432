export default {
  colors: ['#4F5D70', '#28DAC6', '#FFE802', '#227FF4', '#EA5455'],
  legend: {
    show: false,
  },
  chart: {
    type: 'donut',
    fontFamily: 'Montserrat',
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
            label: 'Tổng',
            color: '#227FF4',
            fontFamily: 'Montserrat',
            fontSize: '22px',

          },
        },
      },

    },
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    formatter(val, opts) {
      return `${val.toFixed(0)}%`
    },
    textAnchor: 'middle',
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
    },
    dropShadow: {
      enabled: false,
    },
  },
  labels: ['Người chưa đủ 13 tuổi', 'Người từ 13 tuổi đến chưa đủ 15 tuổi', 'Người từ 15 tuổi đến chưa đủ 18 tuổi', 'Độ tuổi lao động thanh niên', 'Trên độ tuổi lao động'],
}
