<template>
  <div>
    <b-card
      class="card-tiny-line-stats pb-5 d-flex"
      body-class="pb-50"
    >
      <h4 class="mb-1">
        Số lượng toàn tỉnh trong độ tuổi lao động
      </h4>
      <!-- chart -->
      <div class="chart-percent-donut pt-2">

        <vue-apex-charts
          class="char-percent"
          :options="statisticsProfit.chartOptions"
          :series="array"
          height="350px"
          width="350px"
        />
        <b-row
          class="legend-chart-percent-donut w-100"
        >
          <b-row
            v-for="(item, index) in dataList"
            :key="index"
            class="item-legend-chart d-flex align-items-start"
            cols="4"
          >
            <b-col
              cols="2"
            >
              <div :class="['item-color-rating'+ index , 'item-color-rating text-truncate']"></div>
            </b-col>
            <b-col
              class="val-items-legend"
              cols="8"
            >
              <h5 class="text-legnd-chart">
                {{ item.name }}
              </h5>
            </b-col>
            <b-col
              cols="2"
              class="val-items-legend"
            >
              <h5
                class="val-items-legend"
              >{{ item.count }}</h5>
            </b-col>
          </b-row>
        </b-row>

      </div>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Số lượng toàn tỉnh trong độ tuổi lao động</b-card-title>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <vue-apex-charts
          type="bar"
          :options="chartOptions"
          :series="series"
          height="400px"
        />
      </b-card-body>
    </b-card>

    <div class="page-container-table">

      <!--Phần header của bảng -->
      <button-all-header
        :hideSearch="false"
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :arrayExcel="[]"
        :hideAdd="false"
        class="custtom-header pr-1 mb-1"
        @clickExportExcel="dowloadFile"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <!-- <my-pagination
        :totalItems=""
        :currentPage="currentPage"
        @pageClick="handlePageClick"
      /> -->
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import columnChart from '../configChart/ColumChart'
import PercentChart from '../configChart/PercentChart'
import store from '../../store'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstanstApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCol,
    BRow,
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: PercentChart,
        series: [],

      },
      dataPercent: {},
      array: [],
      series: [],
      seriesPercent: [],
      chartOptions: columnChart,

      dataList: [],
      columns: [
        {
          label: 'ĐỘ TUỔI LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG',
          field: 'count',

        },
      ],

    }
  },
  created() {
    this.fetchDataPercent()
  },
  methods: {
    async dowloadFile() {
      const name = 'ToanTinhTheoDoTuoi.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    async fetchDataPercent() {
      this.$showLoading()
      const res = await axiosApiInstance.get(ConstantsApi.API_WORKING_AGE)
      this.dataPercent = res?.data?.data
      this.seriesPercent = Object.values(this.dataPercent)
      this.dataList = [
        {
          name: 'Người chưa đủ 13 tuổi',
          count: this.dataPercent.s13,
        },
        {
          name: 'Người từ  13 tuổi đến chưa đủ 15 tuổi',
          count: this.dataPercent.s1315,
        },
        {
          name: 'Người từ 15 tuổi đến chưa đủ 18 tuổi',
          count: this.dataPercent.s1518,
        },
        {
          name: 'Độ tuổi lao động thanh niên',
          count: this.dataPercent.youth,
        },
        {
          name: 'Trên độ tuổi lao động',
          count: this.dataPercent.onYouth,
        },
      ]
      this.$hideLoading()
      this.array = Object.values(this.dataPercent)
      this.series = [
        {
          name: 'Số lượng toàn tỉnh trong độ tuổi lao động',
          data: this.array,
        },
      ]
    },
  },

}
</script>

<style lang="scss">
  .item-color-rating {
    display: block;
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
  }
  .item-color-rating0 {
    border: 2px solid #4F5D70;
  }
  .item-color-rating1 {
    border: 2px solid #28DAC6;
  }
  .item-color-rating2 {
    border: 2px solid #FFE802;
  }
  .item-color-rating3 {
    border: 2px solid #227FF4;
  }
  .item-color-rating4 {
    border: 2px solid #EA5455;
  }

  .chart-percent-donut{
    display: flex;
    align-items: center;
    margin-left: 20%;
  }

  .legend-chart-percent-donut{
    display: flex;
    margin-left: 10%;
    width: 60%;
  }
  .item-legend-chart{
    display: flex;
    width: inherit;
    padding: 8px 0;
  }

  .val-items-legend{
    font-size: 14px;
    margin-left: -10%;
  }
  .list-items-val{
    margin-left: 30px;
    width: 50px;
  }
@media(max-width: 1800px) {
 .legend-chart-percent-donut{
    margin-left: 5%;
 }
 .val-items-legend{
    margin-left: -6%;
  }
}
@media(max-width: 1364px) {
  .chart-percent-donut{
    display: flex;
    align-items: center;
    margin-left: 5%;
  }

}
@media(min-width: 700px) and (max-width: 1024px) {
  .chart-percent-donut{
    margin-left: 0;
  }
 .list-items-val{
    margin-left: 5px;
    width: 30px;
  }
  .legend-chart-percent-donut{
    display: flex;
    margin-left: 10%;
    width: 60%;
  }
}
@media (min-width: 550px)and(max-width: 700px) {
 .chart-percent-donut{
    display: block;
    margin-left: 20%;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
 }
 .list-items-val{
    width: 30px;
    margin-left: 0;
  }
}

@media (max-width: 550px){

  .card-body{
    width: 100%;
  }
  .chart-percent-donut{
    display: block;
    margin-left: 10%;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
    margin-left: 50px;
 }
 .list-items-val{
    width: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 430px){
.chart-percent-donut{
    display: block;
    margin-left: 0;
  }
  .legend-chart-percent-donut{
    display: flex;
    width: 100%;
    margin-left: 20px;
 }
 .list-items-val{
    width: 30px;
    margin-left: 20px;
  }
  .char-percent{
    margin-left: -20px;
  }
}
</style>
